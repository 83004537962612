// 根据Code生成不同报错信息的方法
exports.install = function(Vue, options) {
  Vue.prototype.goLogin = function() {
    if (this.$route.path != "/") {
      //当前不是登录界面,则跳转到登录界面
      setTimeout(() => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("autoLogin");
        this.$router.replace("/");
      }, 1000);
    }
  };

  Vue.prototype.getMesage = function(code) {
    let res = "";
    switch (parseInt(code)) {
      case 104:
        res = "传入参数异常";
        break;
      case 105:
        res = "程序异常";
        break;
      case 108:
      case 208:
        res = "您的账号已被挤下线,请重新登录~~";
        this.goLogin();
        break;
      case 110:
        res = "账号无权限";
        break;
      case 201:
        res = "此账号不存在";
        // this.goLogin();
        break;
      case 202:
        res = "密码错误";
        break;
      case 203:
        res = "账号已存在";
        break;
      case 205:
        res = "手机号已被注册";
        break;
      case 207:
        res = "无效用户";
        break;
      case 209:
        res = "昵称重复";
        break;
      case 210:
        res = "用户未绑定手机号";
        break;
      case 210:
        res = "用户未绑定手机号";
        break;
      case 211:
        res = "该手机号已绑定其他微信";
        break;
      case 212:
        res = "微信已绑定其他手机号";
        break;
      case 213:
        res = "与原密码一致";
        break;
      case 214:
      case 219:
        res = "账号已过期";
        // res = "该账号已禁用，请联系客服老师";
        this.goLogin();
        break;
      case 301:
        res = "验证码发送失败";
        break;
      case 302:
        res = "验证码错误";
        break;
      case 303:
        res = "今日短信次数已超上限";
        break;
      case 304:
        res = "验证码已失效";
        break;
      case 305:
        res = "验证码发送间隔过短";
        break;
      case 306:
        res = "验证码类型错误";
        break;
      case 422:
        res = "学生已经加入其它班级";
        break;
      case 401:
        res = "存在班级";
        break;
      case 402:
        res = "班级不存在";
        break;
      case 403:
        res = "存在同名班级";
        break;
      case 404:
        res = "班级名太长";
        break;
      case 405:
        res = "班级中无此学生";
        break;
      case 406:
        res = "排课数据冲突";
        break;
      case 407:
        res = "班级无权限";
        break;
      case 408:
        res = "无权限删除班级";
        break;
      case 409:
        res = "班级余额不足";
        break;
      case 410:
        res = "不是班级老师，无权限操作";
        break;
      case 411:
        res = "未到课程结束时间";
        break;
      case 413:
        res = "班级数量已达上限";
        break;
      case 416:
        res = "无有效排课信息";
        break;
      case 417:
        res = "排课次数超出最大可排课次数";
        break;
      case 418:
        res = "班级邀请码错误";
        break;
      case 419:
        res = "学生已在班级中";
        break;
      case 420:
        res = "入班申请已提交，正在等待老师同意，请勿重复提交";
        break;
      case 421:
        res = "您有一份其他班级的入班申请，正在等待老师同意，请勿重复提交";
        break;
      case 422:
        res = "与班级权限冲突";
        break;
      case 423:
        res = "学生状态异常";
        break;
      case 501:
        res = "学校不存在";
        break;
      case 502:
        res = "学校合作已过期";
        break;
      case 503:
        res = "学校已被禁用";
        break;
      case 504:
        res = "学校当前状态不支持该操作";
        break;
      case 505:
        res = "账户余额不足";
        break;
      case 506:
        res = "此科目已开启，不可取消";
        break;
      case 507:
        res = "校区已编辑权限";
        break;
      case 508:
        res = "无有效数据";
        break;
      case 509:
        res = "权限已过期";
        break;
      case 510:
        res = "权限未过期";
        break;
      case 511:
        res = "校长手机号已注册过账号";
        break;

      case 500:
        res = "服务器异常";
        break;
      case 1002:
        res = "人数已达上限";
        break;
      case 1009:
        res = "班级名重复";
        break;
      case 1010:
        res = "合作到期";
        break;
      case 1003:
        res = "班级课程到期";
        break;
      case 1011:
        res = "消息已失效";
        break;
      case 1001:
        res = "班级邀请码不存在";
        break;
      case 1004:
        res = "学生已加入申请的班级";
        break;
      case 1006:
        res = "身份异常";
        break;
      case 1013:
        res = "班级不存在";
        break;
      case 1015:
        res = "账号无该课程的访问权限";
        break;
      case 1018:
        res = "已申请加入班级，请勿重复申请";
        break;
      case 1017:
        res = "已在老师的其他班级里，不可重复加入班级哦";
        break;
      case 1019:
        res = "已申请加入老师名下班级，请等待老师审批";
        break;
      case 1020:
        res = "该班级名额已满，请联系老师";
        break;
      case 1021:
        res = "学生人数已满";
        break;
    }
    return res;
  };
};
