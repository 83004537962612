import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

//导入公共样式
import "./assets/css/default.css"; //default.css
import "./assets/css/animate.css"; //animate.css

//引入iconfont字体库
import "./assets/iconfont/iconfont.css"; //iconfont.css
import "./assets/iconfont/iconfont.ttf"; //iconfont.ttf
import "./assets/iconfont/iconfont.woff"; //iconfont.woff

//引入ElementUI组件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

//引入vue-video-player
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);

//引入网络请求
import http from "./utils/http.js";
Vue.prototype.$http = http;

//引入公共工具类
import commonUtils from "./utils/CommonUtils";
Vue.use(commonUtils);
import code from "./utils/Code";
Vue.use(code);

//引入轮播图 注意这里swiper的版本指定 npm install  swiper@3.1.3 vue-awesome-swiper
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

//引入Vue查看大图(vue-photo-preview) 用法:在img标签添加preview属性 preview值相同即表示为同一组
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
Vue.use(preview);

//引入全局定义的项目配置的网络请求地址
import HttpAddress from "./utils/HttpAddress";
Vue.prototype.$api = HttpAddress;

//引入Loading的全局组件
import Loading from "./components/Loading.vue";
Vue.component("Loading", Loading);

//引入Vue-bus
import VueBus from "vue-bus";
Vue.use(VueBus);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
