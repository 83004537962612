import Vue from "vue";
import Vuex from "vuex";
import createPersistedstate from "vuex-persistedstate";
Vue.use(Vuex);

import user from "./modules/user";
export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    user
  },
  plugins: [
    createPersistedstate({
      //paths是持久化存储state中的哪些数据，如果是模块下具体的数据需要加上模块名称，如payment.amount
      paths: ["user"]
    })
  ]
});
