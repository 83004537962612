<template>
    <div id="app">
        <!-- <keep-alive include="LoginContain"> -->
        <router-view />
        <!-- </keep-alive> -->
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    components: {},
    watch: {
        $route: {
            handler: function (to, from) {
                //获取跳转的路径
                let curPath = to.path;
                //发送事件给TopNav.vue同步顶部导航条按钮
                let that = this;
                that.$bus.emit("watchRoutePath", curPath);
            },
            // 深度观察监听
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
#app {
    min-width: 1300px;
    height: 100%;
}
</style>
