// 封装的Loading组件
<template>
  <div class="loading">
    <div class="loading-icon">
      <div class="demo"></div>
      <div class="demo"></div>
      <div class="demo"></div>
      <div class="demo"></div>
      <div class="demo"></div>
      <div class="demo"></div>
      <div class="demo"></div>
      <div class="demo"></div>
    </div>
    <div class="text">
      {{ text }}
      <!-- <img class="loading_img" src="../assets/img/loading.gif" alt="" /> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "数据加载中..."
    };
  },
  created() {},
  methods: {},
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.loading {
  width: 100%;
  height: calc(100vh - 127px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-icon {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.demo {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: $common_bg;
  position: absolute;
  animation: demo linear 0.8s infinite;
  -webkit-animation: demo linear 0.8s infinite;
  text-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
  font-size: 14px;
}

.demo:nth-child(1) {
  left: 24px;
  top: 2px;
  animation-delay: 0s;
}

.demo:nth-child(2) {
  left: 40px;
  top: 8px;
  animation-delay: 0.1s;
}

.demo:nth-child(3) {
  left: 47px;
  top: 24px;
  animation-delay: 0.1s;
}

.demo:nth-child(4) {
  left: 40px;
  top: 40px;
  animation-delay: 0.2s;
}

.demo:nth-child(5) {
  left: 24px;
  top: 47px;
  animation-delay: 0.4s;
}

.demo:nth-child(6) {
  left: 8px;
  top: 40px;
  animation-delay: 0.5s;
}

.demo:nth-child(7) {
  left: 2px;
  top: 24px;
  animation-delay: 0.6s;
}

.demo:nth-child(8) {
  left: 8px;
  top: 8px;
  animation-delay: 0.7s;
}

@keyframes demo {
  0%,
  40%,
  100% {
    transform: scale(1);
  }

  20% {
    transform: scale(3);
  }
}

@-webkit-keyframes demo {
  0%,
  40%,
  100% {
    transform: scale(1);
  }

  20% {
    transform: scale(3);
  }
}

.text {
  color: $common_bg;
  margin-top: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  // text-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
  font-size: 14px;
}
.loading_img {
  width: 75px;
  height: 25px;
  object-fit: cover;
}
</style>
