//测试环境
// scratch3的测试服访问地址
// const SCRATCH3_URL = "http://cloud.ycsjedu.com/test/scratch3_test/";

// // scratch3的项目测试服播放地址
// const scratch3PlayUrl = "http://cloud.ycsjedu.com/test/scratch3_test/player.html#";

// // 测试服后台接口访问地址
// const BASE_URL = "http://codefrog.zdwkj.com/croak_test/croak_cloud_test/";

// 正式环境
// scratch3的正式服访问地址
const SCRATCH3_URL = "http://cloud.ycsjedu.com/scratch3/";

//scratch3的项目正式服播放地址
const scratch3PlayUrl = "http://cloud.ycsjedu.com/scratch3/player.html#";

// 正式服后台接口访问地址
const BASE_URL = "https://codefrog.zdwkj.com/croak/croak_cloud/";

//课程中心
const LESSON_CENTER = BASE_URL + "api/CurriculumController";

//老师端班级管理
const TEACHER_CLASS_MANAGER = BASE_URL + "api/TeacherMsg";

//登录模块接口
const LOGIN = BASE_URL + "api/LoginMsg";

//学生端班级管理
const STUDENT_CLASS_MSG = BASE_URL + "api/ClassMsg";

//上传图片,视频,打开项目的接口
const POST_ACTION = BASE_URL + "api/UserWorkController";

//创作中心接口
const WRITE_CENTER = BASE_URL + "api/UserWorkController";

//教研中心
const RESEARCH_PROJECT = BASE_URL + "api/UserWorkController";

//系统消息
const SYS_MSG = BASE_URL + "api/LoginMsg";

//获取未读消息
const UN_READN_NUM = BASE_URL + "api/LoginMsg";

//赛事中心请求的API基类地址-测试服
// const BASE_URL_COMPETITONO = "https://codefrog.zdwkj.com/croak_test/croak_cloud_test/api/";
//正式服
const BASE_URL_COMPETITONO = "https://codefrog.zdwkj.com/croak/croak_cloud/api/";

//赛事中心请求的API接口
const COMPETITION_API = BASE_URL_COMPETITONO + "EventController";

export default {
  SCRATCH3_URL,
  scratch3PlayUrl,
  LESSON_CENTER,
  TEACHER_CLASS_MANAGER,
  LOGIN,
  STUDENT_CLASS_MSG,
  POST_ACTION,
  WRITE_CENTER,
  RESEARCH_PROJECT,
  SYS_MSG,
  UN_READN_NUM,
  COMPETITION_API
};
