import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/LoginContain",
    name: "LoginContain",
    component: () => import("@/views/LoginContain.vue"),
    children: [
      {
        path: "CourseCenter",
        name: "CourseCenter",
        meta: {
          title: "课程中心"
        },
        component: () => import("@/views/coursecenter/CourseCenter.vue")
      },
      {
        path: "DownLoad",
        name: "DownLoad",
        meta: {
          title: "资源下载中心"
        },
        component: () => import("@/views/download/DownLoad.vue")
      },
      {
        path: "WriteCenter",
        name: "WriteCenter",
        meta: {
          title: "创作中心"
        },
        component: () => import("@/views/writecenter/WriteCenter.vue")
      },
      {
        path: "ResearchCenter",
        name: "ResearchCenter",
        meta: {
          title: "教研中心"
        },
        component: () => import("@/views/researchcenter/ResearchCenter.vue")
      },
      {
        path: "SysMessage",
        name: "SysMessage",
        meta: {
          title: "系统消息"
        },
        component: () => import("@/views/message/SysMessage.vue")
      },
      {
        path: "ChooseChapter",
        name: "ChooseChapter",
        meta: {
          title: "选择章节"
        },
        component: () => import("@/views/coursecenter/ChooseChapter.vue")
      },
      {
        path: "ChooseLesson",
        name: "ChooseLesson",
        meta: {
          title: "选择课程"
        },
        component: () => import("@/views/coursecenter/ChooseLesson.vue")
      },
      {
        path: "TeacherManagerClass",
        name: "TeacherManagerClass",
        meta: {
          title: "教师班级管理"
        },
        component: () => import("@/views/classmanager/TeacherManagerClass.vue")
      },
      {
        path: "StudentMyClass",
        name: "StudentMyClass",
        meta: {
          title: "学生我的班级"
        },
        component: () => import("@/views/studentCenter/StudentMyClass.vue")
      },
      {
        path: "stuCenter",
        name: "stuCenter",
        meta: {
          title: "学生个人资料"
        },
        component: () => import("@/views/studentCenter/stuCenter.vue")
      }
    ]
  },
  {
    path: "/Study",
    name: "Study",
    meta: {
      title: "开始学习"
    },
    component: () => import("@/views/study/Study.vue"),
    children: [
      {
        path: "PptStudy",
        name: "PptStudy",
        meta: {
          title: "Ppt"
        },
        component: () => import("@/views/study/PptStudy.vue")
      },
      {
        path: "ScratchStudy",
        name: "ScratchStudy",
        meta: {
          title: "Scratch"
        },
        component: () => import("@/views/study/ScratchStudy.vue")
      },
      {
        path: "VideoStudy",
        name: "VideoStudy",
        meta: {
          title: "慕课视频"
        },
        component: () => import("@/views/study/VideoStudy.vue")
      },
      {
        path: "ExercisesStudy",
        name: "ExercisesStudy",
        meta: {
          title: "习题"
        },
        component: () => import("@/views/study/ExercisesStudy.vue")
      },
      {
        path: "AppraisalStudy",
        name: "AppraisalStudy",
        meta: {
          title: "测评"
        },
        component: () => import("@/views/study/AppraisalStudy.vue")
      }
    ]
  },
  {
    //赛事中心
    path: "/competitonCenter",
    name: "competitonCenter",
    component: () => import("@/views/competitionCenter/Competition.vue"),
    children: [
      {
        path: "DownLoad",
        name: "DownLoad",
        meta: {
          title: "资源下载中心"
        },
        component: () => import("@/views/download/DownLoad.vue")
      },
      {
        path: "LessonCenter",
        name: "LessonCenter",
        meta: {
          title: "赛事中心1-课程中心"
        },
        component: () => import("@/views/competitionCenter/LessonCenter.vue")
      },
      {
        path: "ChapterCenter",
        name: "ChapterCenter",
        meta: {
          title: "赛事中心2-选择章节"
        },
        component: () => import("@/views/competitionCenter/ChapterCenter.vue")
      },
      {
        path: "CompetitionChooseLesson",
        name: "CompetitionChooseLesson",
        meta: {
          title: "赛事中心3-选择课程"
        },
        component: () => import("@/views/competitionCenter/CompetitionChooseLesson.vue")
      }
    ]
  },
  {
    path: "/CompetitionCenterStudy",
    name: "CompetitionCenterStudy",
    meta: {
      title: "赛事中心开始学习"
    },
    component: () => import("@/views/study/CompetitionCenterStudy.vue")
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

//下面这段话是为了解决vue跳转到子路由时报错error:Redirected from via a navigation guard 报错信息的
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

export default router;

//获取Vue对象
// let vm = Vue.prototype;

//这里使用路由守卫来判断用户是否登录用户信息是否存在,如果用户信息不存在,则跳转到首页登录界面
// router.beforeEach((to, from, next) => {
//   if (to.path != '/') {
//     // 判定userInfo是否存在
//     let userInfo = localStorage.getItem("userInfo")
//     if (vm.isEmpty(userInfo)) {
//       //如果没有用户信息则需要跳转到登录界面
//       localStorage.removeItem("autoLogin");
//       next('/')
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })
