const state = {
  //登录状态
  loginState: false,
  //用户信息
  userInfo: {},
  //登录后的下拉框数组信息
  navArr: []
};

// 获取方式this.$store.getters.方法名
const getters = {
  getLoginState() {
    return state.loginState;
  },
  getUerInfo() {
    return state.userInfo;
  }
};

//this.$store.commit("方法名",参数);
const mutations = {
  setLoginState: (state, hasLogin) => {
    state.loginState = hasLogin;
  },

  //设置用户信息
  setUserInfo: (state, userInfo) => {
    state.userInfo = userInfo;
  },

  //设置下拉框的数组信息
  setNavArr: (state, navArr) => {
    state.navArr = navArr;
  }
};

//this.$store.dispatch("方法名",参数);
// const acitons = {
//   setLoginState: (context, hasLogin) => {
//     context.commit('setLoginState', hasLogin)
//   }

// setUserInfo: (context, userInfo) => {
//   context.commit("setUserInfo", userInfo);
// };
// }

export default {
  //用于在全局引用此文件里的方法时标识这一个的文件名,解决命名冲突问题
  namespaced: true,
  state,
  getters,
  mutations
  // acitons
};
